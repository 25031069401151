import axios from 'axios'
import AspectModel from '../models/AspectModel';
import AspectsCollection from '../collections/AspectsCollection';

export default {
    baseUrl() {
        if (window.location.origin.includes('localhost')) {
            switch(process.env.VUE_APP_SITE) {
                case 'diversiteit-scholen':
                    return 'http://leeromgeving-diversiteit-scholen.test/wp/wp-json/digitaltool';
            }
            return 'http://leeromgeving-diversiteit.test/wp/wp-json/digitaltool';
        }

        return `${window.location.origin}/wp/wp-json/digitaltool`;
    },

    async fetchThemes() {
        return await this.request(`/themes/`);
    },

    async fetchTheme(theme_id) {
        return await this.request(`/themes/${theme_id}/`);
    },

    async fetchPage(page_id) {
        return await this.request(`/page/${page_id}/`);
    },

    async fetchIntervention(intervention) {
        return await this.request(`/interventions/${intervention}/`);
    },

    async fetchAspect(aspect_id ) {
        const aspect = await this.request(`/aspects/${aspect_id}/`);
        return new AspectModel(aspect);
    },

    async fetchAspects(theme) {
        let aspects = await this.request(`/aspects/?theme=${theme}`);
        aspects = aspects.map((aspect) => {
            return new AspectModel(aspect);
        });

        return new AspectsCollection(aspects);
    },

    async fetchKnowledgeBaseItemsByTheme(theme) {
        return await this.request(`/knowledge/?theme=${theme}`);
    },

    request(endpoint) {
        return new Promise((resolve) => {
            axios.get(`${this.baseUrl()}${endpoint}`).then(response => {
                resolve(response.data.data);
            })
        });
    }

}
